






































































import Vue from "vue";

export default Vue.extend({
  name: "InsuranceImageDialog",
  props: {
    image1Encoded: String,
    image2Encoded: String
  },

  data() {
    return {
      dialog: false,

      image1Src: undefined as string | undefined,
      image2Src: undefined as string | undefined
    };
  },

  methods: {
    // Decode base64 encoding
    init(): void {
      if (this.image1Encoded) this.image1Src = `data:image;base64, ${this.image1Encoded}`;
      if (this.image2Encoded) this.image2Src = `data:image;base64, ${this.image2Encoded}`;
    },

    close(): void {
      this.dialog = false;

      this.image1Src = undefined as string | undefined;
      this.image2Src = undefined as string | undefined;
    }
  }
});
