var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"no-padding",attrs:{"id":"home"}},[_c('v-tabs',{attrs:{"background-color":"primary","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":'#' + _vm.tabs[0]},on:{"click":function($event){return _vm.getReimbursementRequests(1)}}},[_vm._v(" Pending ("+_vm._s(_vm.pendingCount)+") "),_c('v-icon',[_vm._v("mdi-clock-outline")])],1),_c('v-tab',{attrs:{"href":'#' + _vm.tabs[1]},on:{"click":function($event){return _vm.getReimbursementRequests(2)}}},[_vm._v(" In Progress ("+_vm._s(_vm.inProgressCount)+") "),_c('v-icon',[_vm._v("mdi-page-next-outline")])],1),_c('v-tab',{attrs:{"href":'#' + _vm.tabs[2]},on:{"click":function($event){return _vm.getReimbursementRequests(3)}}},[_vm._v(" Complete ("+_vm._s(_vm.completedCount)+") "),_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-spacer'),_c('div',{staticClass:"flex-centered"},[_c('v-btn',{attrs:{"loading":_vm.isLoading,"text":"","large":""},on:{"click":function($event){return _vm.getReimbursementRequests(_vm.tab)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab,attrs:{"value":tab}},[_c('v-card',[_c('v-card-title',[_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rrData,"loading":_vm.isLoading,"sort-by":"createdOn"},scopedSlots:_vm._u([{key:"item.doctor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.doctor.firstName)+" "+_vm._s(item.doctor.lastName)+" ")]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patientFirstName)+" "+_vm._s(item.patientLastName)+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.createdOn))+" ")]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('DialogReimbursementRequest',{attrs:{"reimbursementRequest":item},on:{"refresh-requests":function($event){return _vm.getReimbursementRequests(tab)}}}),(item.pharmacistUserID)?[(item.requestStatus === 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-decagram ")])]}}],null,true)},[_c('span',[_vm._v("Completed by "+_vm._s(item.pharmacistName))])]):_vm._e(),(item.insuranceCardPhotoFileUploadID || item.insuranceCardPhoto2FileUploadID)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-image ")])]}}],null,true)},[_c('span',[_vm._v("Images attached")])]):_vm._e()]:_vm._e()]}}],null,true)})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }