
















































































































































































































































































































































































































// Vue
import Vue, { PropType } from "vue";
// dayjs
import dayjs from "dayjs";
// Models
import { Province, RequestStatus } from "@/core/_models/_enumerations";
import { ReimbursementRequest } from "@/core/_models/reimbursementRequest.model";
import { DrugResponseOption } from "@/core/_models/drugResponseOption.model";
// Components
import DialogConfirmCoverageSelection from "@/views/home/_dialogs/DialogConfirmCoverageSelection.vue";
import InsuranceImageDialog from "@/views/home/_dialogs/InsuranceImageDialog.vue";
// API
import {
  DrugResponseOptionClient,
  FileUploadClient,
  InsuranceCompanyClient,
  ReimbursementRequestClient,
  ReimbursementRequestInfo
} from "@/core/api/PharmacyReimbursementApi";
import { InsuranceCompanyResponse } from "@/core/_models/insuranceCompanyResponse.model";
import { DrugResponse } from "@/core/_models/drugResponse.model";
import { User } from "@/core/_models/user.model";
import { mapGetters } from "vuex";

// API Clients
const rrClient = new ReimbursementRequestClient();
const insuranceClient = new InsuranceCompanyClient();
const fileUploadClient = new FileUploadClient();
const droClient = new DrugResponseOptionClient();

export default Vue.extend({
  name: "DialogReimbursementRequest",
  components: { DialogConfirmCoverageSelection, InsuranceImageDialog },
  props: {
    reimbursementRequest: {} as PropType<ReimbursementRequest>
  },

  data() {
    return {
      dialog: false,
      imageLoading: false,

      // Patient Information
      patientInfo: {
        firstName: undefined as string | undefined,
        lastName: undefined as string | undefined,
        dateOfBirth: undefined as string | undefined,
        province: undefined as string | undefined,
        relationship: undefined as string | undefined
      },

      // Doctor Information
      doctorInfo: {
        firstName: undefined as string | undefined,
        lastName: undefined as string | undefined,
        licenseNumber: undefined as string | undefined
      },

      // Pharmacist Information
      pharmacistInfo: {
        pharmacist: undefined as User | undefined,
        pharmacistName: undefined as string | undefined,
        pharmacistUserID: undefined as string | undefined
      },

      // Drug Information
      drugInfo: {
        name: undefined as string | undefined,
        din: undefined as string | undefined,
        dosingRecommendations: undefined as string | undefined
      },

      // Insurance Information
      insuranceInfo: {
        carrier: undefined as string | undefined,
        adjudicator: undefined as string | undefined,
        carrierId: undefined as string | undefined,
        clientId: undefined as string | undefined,
        clientIdName: undefined as string | undefined,
        groupId: undefined as string | undefined,
        groupIdName: undefined as string | undefined
      },

      insuranceFields: {
        thirdFieldId: undefined as string | undefined,
        thirdFieldName: undefined as string | undefined,
        fourthFieldId: undefined as string | undefined,
        fourthFieldName: undefined as string | undefined,
        fifthFieldId: undefined as string | undefined,
        fifthFieldName: undefined as string | undefined,
        sixthFieldId: undefined as string | undefined,
        sixthFieldName: undefined as string | undefined
      },

      // Insurance Photos
      insurancePhotos: {
        insurancePhoto1: undefined as string | undefined,
        insurancePhoto2: undefined as string | undefined
      },

      // Drug Response Options (coverage)
      drugResponseOptions: undefined as DrugResponse[] | undefined,
      selectedDrugResponseOption: undefined as DrugResponseOption | undefined,
      drugResponseOptionID: undefined as number | undefined
    };
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },

  methods: {
    init(): void {
      // ? The dynamic field "data" might be returned from here?
      console.log("this.reimbursementRequest", this.reimbursementRequest);

      if (!this.reimbursementRequest) return;

      // Patient Info
      this.patientInfo = {
        firstName: this.reimbursementRequest.patientFirstName ?? "N/A",
        lastName: this.reimbursementRequest.patientLastName ?? "N/A",
        dateOfBirth: this.reimbursementRequest.dateOfBirth?.toString() ?? "N/A",
        province: Province[this.reimbursementRequest.province!] ?? "N/A",
        relationship: this.reimbursementRequest.patientRelationToCardHolder ?? "N/A"
      };

      // Doctor Information
      this.doctorInfo = {
        firstName: this.reimbursementRequest.doctorFirstName ?? "N/A",
        lastName: this.reimbursementRequest.doctorLastName ?? "N/A",
        licenseNumber: this.reimbursementRequest.doctor?.cpsoNumber ?? "N/A"
      };

      // Pharmacist Information
      this.pharmacistInfo = {
        pharmacist: this.reimbursementRequest.pharmacist,
        pharmacistName: this.reimbursementRequest.pharmacistName,
        pharmacistUserID: this.reimbursementRequest.pharmacistUserID
      };

      // Drug Information
      this.drugInfo = {
        name: this.reimbursementRequest.drugName ?? "N/A",
        din: this.reimbursementRequest.din ?? "N/A",
        dosingRecommendations: this.reimbursementRequest.dosingRecommendations ?? "N/A"
      };

      // Insurance Information and Photos
      this.getInsuranceData();
      this.getInsurancePhotos();

      // Drug Response Options (coverage dropdown)
      this.getDrugResponseOptions();

      // Set selectedDrugResponseOption if the request has one ("Completed" request)
      if (this.reimbursementRequest.drugResponseOptionID) {
        this.drugResponseOptionID = this.reimbursementRequest.drugResponseOptionID;
        this.selectedDrugResponseOption = this.reimbursementRequest.drugResponseOption;
      }
    },

    async markAsInProgress(): Promise<void> {
      if (!this.reimbursementRequest.reimbursementRequestID) return;

      // If the request status is NOT "Pending" and NOT "ContactingInsuranceCompany" - exit out, do nothing.
      if (
        this.reimbursementRequest.requestStatus !== RequestStatus.Pending.valueOf() &&
        this.reimbursementRequest.requestStatus !== RequestStatus.ContactingInsuranceCompany.valueOf()
      )
        return;

      // If the request status is NOT "Answered", update the pharmacistUserID with the currentUser's accountID
      let pharmacistUserID: string | undefined = this.reimbursementRequest.pharmacistUserID;
      if (this.reimbursementRequest.requestStatus !== RequestStatus.Answered.valueOf()) {
        pharmacistUserID = this.currentUser.accountID;
      }

      // Build payload
      const payload = {
        doctorID: this.reimbursementRequest.doctorID,
        doctorChildAccountID: this.reimbursementRequest.doctorChildAccountID,
        pharmacistUserID: pharmacistUserID,
        insuranceCompanyName: this.reimbursementRequest.insuranceCompanyName,

        // ! These CANNOT be null when sent to the db - causes 500 errors !
        clientID: this.reimbursementRequest.clientId ?? "",
        groupID: this.reimbursementRequest.groupId ?? "",
        // ! These CANNOT be null when sent to the db - causes 500 errors !

        thirdFieldID: this.reimbursementRequest.thirdFieldID,
        forthFieldID: this.reimbursementRequest.forthFieldID,
        fifthFieldID: this.reimbursementRequest.fifthFieldID,
        sixthFieldID: this.reimbursementRequest.sixthFieldID,
        province: this.reimbursementRequest.province,
        patientFirstName: this.reimbursementRequest.patientFirstName,
        patientLastName: this.reimbursementRequest.patientLastName,
        patientRelationToCardHolder: this.reimbursementRequest.patientRelationToCardHolder,
        drugIdentifier: this.reimbursementRequest.drugIdentifier,
        dateOfBirth: this.reimbursementRequest.dateOfBirth,        
        din: this.reimbursementRequest.din,
        optionName: this.reimbursementRequest.optionName,

        requestStatus: RequestStatus.InProgress.valueOf(),
        
        drugResponseOptionID: this.reimbursementRequest.drugResponseOptionID,
        drugResponseOtherText: this.reimbursementRequest.drugResponseOtherText,
        specialAuthorizationFormID: this.reimbursementRequest.specialAuthorizationFormID,
        specialAuthorizationFormStatus: this.reimbursementRequest.specialAuthorizationFormStatus,
        specialAuthorizationPdfFile: this.reimbursementRequest.specialAuthorizationPdfFile,
      } as ReimbursementRequestInfo;

      try {
        await rrClient.reimbursementRequestPUT(
          this.reimbursementRequest.reimbursementRequestID,
          payload
        );
      } catch (error) {
        console.error(error);
      }
    },

    async getInsuranceData(): Promise<void> {
      try {
        const data: InsuranceCompanyResponse = await insuranceClient.getInsuranceCompanyByName(
          this.reimbursementRequest.insuranceCompanyName!
        );
        if (!data) return;

        // ? The dynamic field "labels" might be fetched from here?
        console.log("InsuranceCompany data", data);
        console.log("RR", this.reimbursementRequest);

        // Insurance Company
        this.insuranceInfo = {
          carrier: this.reimbursementRequest.insuranceCompanyName,
          adjudicator: data.adjudicator,
          carrierId: data.carrierId,
          clientId: this.reimbursementRequest.clientId, 
          clientIdName: data.clientIdName,
          groupId: this.reimbursementRequest.groupId,
          groupIdName: data.groupIdName
        };
        

        // Insurance "fields"
        this.insuranceFields = {
          thirdFieldId: this.reimbursementRequest.thirdFieldID,
          thirdFieldName: data.thirdFieldName,
          fourthFieldId: this.reimbursementRequest.forthFieldID,
          fourthFieldName: data.forthFieldName,
          fifthFieldId: this.reimbursementRequest.fifthFieldID,
          fifthFieldName: data.fifthFieldName,
          sixthFieldId: this.reimbursementRequest.sixthFieldID,
          sixthFieldName: data.sixthFieldName
        };
      } catch (error) {
        console.error(error);
      }
    },

    async getInsurancePhotos(): Promise<void> {
      
      // Get photo1
      if (this.reimbursementRequest.insuranceCardPhotoFileUploadID) {
        try {
          this.imageLoading = true;  
          const photo1Encoded = await fileUploadClient.downloadFile(
            this.reimbursementRequest.insuranceCardPhotoFileUploadID
          );
          console.log("insuranceCardPhotoFileUploadID",this.reimbursementRequest.insuranceCardPhotoFileUploadID)
          console.log("photo1",photo1Encoded)
          if (!photo1Encoded) return;
          this.insurancePhotos.insurancePhoto1 = photo1Encoded;
          
        } catch (error) {
          console.error(error);
        }
      }

      // Get photo2
      if (this.reimbursementRequest.insuranceCardPhoto2FileUploadID) {
        try {
          this.imageLoading = true;  
          const photo2Encoded = await fileUploadClient.downloadFile(
            this.reimbursementRequest.insuranceCardPhoto2FileUploadID
          );
          console.log("insuranceCardPhoto2FileUploadID",this.reimbursementRequest.insuranceCardPhoto2FileUploadID)
          console.log("photo2",photo2Encoded)
          if (!photo2Encoded) return;
          this.insurancePhotos.insurancePhoto2 = photo2Encoded;
          
        } catch (error) {
          console.error(error);
        }

        this.imageLoading = false;
      }
    },

    async getDrugResponseOptions(): Promise<void> {
      if (!this.reimbursementRequest.drugIdentifier) return;

      try {
        const data = await droClient.getForDrug(this.reimbursementRequest.drugIdentifier);
        if (!data) return;

        const drugResponses: DrugResponse[] = [];

        // Map data to DrugResponse
        // ! Binding the direct data (of type DrugResponseOption) was causing rendering errors.
        // ! I think it was something to do with the html that was stored in the properties of type DrugResponseOption;
        // ! binding the "title" to the item-text in the v-select was rendering completely different properties.
        data.map((element: DrugResponseOption) => {
          drugResponses.push({
            title: element.title,
            response: element
          });
        });

        this.drugResponseOptions = drugResponses;

        // console.log("this.drugResponseOptions", this.drugResponseOptions);
      } catch (error) {
        console.error(error);
      }
    },

    formatDate(date: string | undefined): string | undefined {
      if (!date) return;
      return dayjs(date).format("MMMM D, YYYY");
    },

    close(isComplete: boolean): void {
      if(!isComplete){        
        // Mark the request as "In Progress" if the status is not complete
        this.markAsInProgress();
      }
      
      // Patient Information
      this.patientInfo = {
        firstName: undefined as string | undefined,
        lastName: undefined as string | undefined,
        dateOfBirth: undefined as string | undefined,
        province: undefined as string | undefined,
        relationship: undefined as string | undefined
      };

      // Doctor Information
      this.doctorInfo = {
        firstName: undefined as string | undefined,
        lastName: undefined as string | undefined,
        licenseNumber: undefined as string | undefined
      };

      // Drug Information
      this.drugInfo = {
        name: undefined as string | undefined,
        din: undefined as string | undefined,
        dosingRecommendations: undefined as string | undefined
      };

      // Insurance Information
      this.insuranceInfo = {
        carrier: undefined as string | undefined,
        adjudicator: undefined as string | undefined,
        carrierId: undefined as string | undefined,
        clientId: undefined as string | undefined,
        clientIdName: undefined as string | undefined,
        groupId: undefined as string | undefined,
        groupIdName: undefined as string | undefined
      };

      // Insurance "fields"
      this.insuranceFields = {
        thirdFieldId: undefined as string | undefined,
        thirdFieldName: undefined as string | undefined,
        fourthFieldId: undefined as string | undefined,
        fourthFieldName: undefined as string | undefined,
        fifthFieldId: undefined as string | undefined,
        fifthFieldName: undefined as string | undefined,
        sixthFieldId: undefined as string | undefined,
        sixthFieldName: undefined as string | undefined
      };

      // Insurance Photos
      this.insurancePhotos = {
        insurancePhoto1: undefined as string | undefined,
        insurancePhoto2: undefined as string | undefined
      };

      // Drug Response Options (coverage)
      this.drugResponseOptions = undefined as DrugResponse[] | undefined;
      this.selectedDrugResponseOption = undefined as DrugResponseOption | undefined;

      this.dialog = false;
      this.$emit("refresh-requests");
    }
  }
});
