


































































































































// Vue
import Vue, { PropType } from "vue";
// PNotify
import { success, error, notice } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import * as Confirm from "@pnotify/confirm";
// Models
import { ReimbursementRequest } from "@/core/_models/reimbursementRequest.model";
import { DrugResponse } from "@/core/_models/drugResponse.model";
import { RequestStatus, SpecialAuthorizationFormStatus } from "@/core/_models/_enumerations";
// API
import {
  ReimbursementRequestInfo,
  ReimbursementRequestClient,
  SpecialAuthorizationFormClient
} from "@/core/api/PharmacyReimbursementApi";
import { mapGetters } from "vuex";

// API Clients
const rrClient = new ReimbursementRequestClient();
const specialAuthClient = new SpecialAuthorizationFormClient();

export default Vue.extend({
  name: "DialogConfirmCoverageSelection",
  props: {
    disabled: Boolean,
    coverageResponse: {} as PropType<DrugResponse>,
    reimbursementRequest: {} as PropType<ReimbursementRequest>
  },

  data() {
    return {
      dialog: false,
      isLoading: false,

      coverageTitle: undefined as string | undefined,

      headerText: undefined as string | undefined,
      bodyText: undefined as string | undefined,
      footerText: undefined as string | undefined,

      isEditingBody: false,
      newBodyText: undefined as string | undefined
    };
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },

  methods: {
    init(): void {
      // console.log("this.coverageResponse", this.coverageResponse);

      if (this.coverageResponse) {
        this.coverageTitle = this.coverageResponse.title;

        this.headerText = this.coverageResponse.response.header;
        this.bodyText = this.coverageResponse.response.notes;
        this.footerText = this.coverageResponse.response.footer;
      }
    },

    async sendResponse(): Promise<void> {
      if (!this.reimbursementRequest.reimbursementRequestID) return;

      const newAuthStatus: SpecialAuthorizationFormStatus = this.coverageResponse.response.isSpecialAuth ? SpecialAuthorizationFormStatus.Pending : SpecialAuthorizationFormStatus.NotApplicable
      const payload = {
        doctorID: this.reimbursementRequest.doctorID,
        doctorChildAccountID: this.reimbursementRequest.doctorChildAccountID,
        pharmacistUserID: this.currentUser.accountID,
        insuranceCompanyName: this.reimbursementRequest.insuranceCompanyName,

        // ! These CANNOT be null when sent to the db - causes 500 errors !
        clientID: this.reimbursementRequest.clientId ?? "",
        groupID: this.reimbursementRequest.groupId ?? "",
        // ! These CANNOT be null when sent to the db - causes 500 errors !

        thirdFieldID: this.reimbursementRequest.thirdFieldID,
        forthFieldID: this.reimbursementRequest.forthFieldID,
        fifthFieldID: this.reimbursementRequest.fifthFieldID,
        sixthFieldID: this.reimbursementRequest.sixthFieldID,
        province: this.reimbursementRequest.province,
        patientFirstName: this.reimbursementRequest.patientFirstName,
        patientLastName: this.reimbursementRequest.patientLastName,
        patientRelationToCardHolder: this.reimbursementRequest.patientRelationToCardHolder,
        drugIdentifier: this.reimbursementRequest.drugIdentifier,
        dateOfBirth: this.reimbursementRequest.dateOfBirth,
        din: this.reimbursementRequest.din,
        optionName: this.reimbursementRequest.optionName,

        // ? Updating the status of the request here to Answered.
        requestStatus: RequestStatus.Answered.valueOf(),
        // ? Updating request response option data here.

        drugResponseOptionID: this.coverageResponse.response.drugResponseOptionID,
        drugResponseOtherText: this.bodyText,
        specialAuthorizationFormID: this.reimbursementRequest.specialAuthorizationFormID,
        specialAuthorizationFormStatus: newAuthStatus.valueOf(),
        specialAuthorizationPdfFile: this.reimbursementRequest.specialAuthorizationPdfFile
      } as ReimbursementRequestInfo;

      try {
        this.isLoading = true;
        // console.log("payload", payload);
        await rrClient.reimbursementRequestPUT(
          this.reimbursementRequest.reimbursementRequestID,
          payload
        );

        // Check isSpecialAuth
        if (this.coverageResponse.response.isSpecialAuth) {
          try {
            let forms: any[] = await specialAuthClient.getSpecialAuthorizationFormsForDrug(
              this.reimbursementRequest.drugIdentifier!
            );

            console.log("forms", forms);

            // Filter for current province
            forms = forms.filter(
              (x) =>
                !x.Province.HasValue ||
                (x.Province.HasValue && x.Province.Value === this.reimbursementRequest.province)
            );

            // Filter by insurance company
            forms = forms.filter(
              (x) =>
                !x.InsuranceCompanyID.HasValue ||
                x.InsuranceCompanyName === this.reimbursementRequest.insuranceCompanyName
            );

            if (!forms.length) {
              this.toastNoForms();
            }

            // TODO - what do we do if there are forms?
          } catch (error) {
            console.error(error);
          }
        }

        this.toastSuccess();
        this.close();
        this.$emit("refresh-requests", true);
      } catch (error) {
        console.error(error);
        this.toastError();
      } finally {
        this.isLoading = false;
      }
    },

    toastSuccess(): void {
      success({
        title: "Response Sent",
        text: "Your response was sent to the doctor.",
        hide: true,
        closer: false,
        sticker: false,
        destroy: true,
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: (notice: any) => notice.close()
                }
              ]
            }
          ]
        ])
      });
    },

    toastError(): void {
      error({
        title: "Error",
        text: "There was an error sending your response. Please try again.",
        hide: true,
        closer: false,
        sticker: false,
        destroy: true,
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: (notice: any) => notice.close()
                }
              ]
            }
          ]
        ])
      });
    },

    toastNoForms(): void {
      notice({
        title: "Action Required",
        text: "No special authorization forms on file. Please obtain and email copy to Mcheers@patientcaresolutions.com",
        hide: true,
        closer: false,
        sticker: false,
        destroy: true,
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: (notice: any) => notice.close()
                }
              ]
            }
          ]
        ])
      });
    },

    close(): void {
      this.dialog = false;
      this.isEditingBody = false;

      this.coverageTitle = undefined as string | undefined;

      this.headerText = undefined as string | undefined;
      this.bodyText = undefined as string | undefined;
      this.footerText = undefined as string | undefined;

      this.newBodyText = undefined as string | undefined;
    }
  }
});
