








































































































// Vue
import Vue from "vue";
import { mapActions } from "vuex";
// dayjs
import dayjs from "dayjs";
// PNotify
import { error, notice } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import * as Confirm from "@pnotify/confirm";
// Models
import { RequestStatus } from "@/core/_models/_enumerations";
import { ReimbursementRequest } from "@/core/_models/reimbursementRequest.model";
// Components
import DialogReimbursementRequest from "@/views/home/_dialogs/DialogReimbursementRequest.vue";
// API
import { ReimbursementRequestClient } from "@/core/api/PharmacyReimbursementApi";

const rrClient = new ReimbursementRequestClient();

export default Vue.extend({
  name: "Home",
  components: { DialogReimbursementRequest },

  data() {
    return {
      isLoading: false,
      pollingFetch: {} as any,
      pollingCheckRequests: {} as any,
      inProgressCount: 0,
      pendingCount: 0,
      completedCount: 0,

      // Tabs
      tab: RequestStatus.Pending.valueOf().toString(),
      tabs: [
        RequestStatus.Pending.valueOf().toString(),
        RequestStatus.InProgress.valueOf().toString(),
        RequestStatus.Answered.valueOf().toString()
      ],

      // Datatable data
      rrData: undefined as ReimbursementRequest[] | undefined,

      // Datatable headers
      headers: [
        { text: "Doctor", value: "doctor" },
        { text: "Drug", value: "drug.name" },
        { text: "Patient", value: "patient" },
        { text: "Insurance", value: "insuranceCompanyName" },
        { text: "Received", value: "createdOn" },
        { text: "Last Opened By", value: "pharmacistName" },
        { text: "", value: "open", sortable: false }
      ]
    };
  },

  async mounted() {
    this.getReimbursementRequests(parseInt(this.tab));

    // Start polling fetch on the default "status" tab - Pending
    this.pollingFetch = setInterval(() => {
      this.getReimbursementRequests(RequestStatus.Pending.valueOf());
    }, 30000);  

    // Check for "Pending" and "In Progress" requests
    this.checkRequests();

    // Request permission from user to send browser notifications.
    await Notification.requestPermission();
  },

  // Clear the polling fetches on destroy
  destroyed() {
    clearInterval(this.pollingFetch);
  },

  methods: {
    ...mapActions({ canLogout: "canLogout" }),
    
    async getReimbursementRequests(status: number): Promise<void> {
      this.isLoading = true;

      try {
        var previousDate = new Date(Date.now() - 86400000)  // that is: 24 * 60 * 60 * 1000 = 24 hours
 
        var pendingData = await rrClient.getByStatus(1);
        pendingData = pendingData.filter((a : ReimbursementRequest) => {
          var date = new Date(a.createdOn!);
          return (date >= previousDate);
        });

        var inProgressData = await rrClient.getByStatus(2);
        inProgressData = inProgressData.filter((a : ReimbursementRequest) => {
          var date = new Date(a.createdOn!);
          return (date >= previousDate);
        });

        var completedData = await rrClient.getByStatus(3); 
        completedData = completedData.filter((a : ReimbursementRequest) => {
          var date = new Date(a.createdOn!);
          return (date >= previousDate);
        });
        
        if (status == 1){
          this.rrData = pendingData;       
        }else if (status == 2){           
          this.rrData = inProgressData;  
        }else if (status == 3){          
          this.rrData = completedData;  
        }else {
          return;
        }      

        this.pendingCount = pendingData.length
        this.inProgressCount = inProgressData.length
        this.completedCount = completedData.length

        // Commit canlogout to state
        this.canLogout(this.pendingCount == 0 && this.inProgressCount == 0);
      } catch (error) {
        this.toastError();
        // in case if something goes wrong with the api request allow user to logout
        this.canLogout(true);
        // console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    checkRequests(): void {
      // Clear previous polling checkRequests
      clearInterval(this.pollingCheckRequests);

      this.pollingCheckRequests = setInterval(async () => {
        // Check for "Pending" and "In Progress" Requests
        try {
          const pendingData = await rrClient.getByStatus(RequestStatus.Pending.valueOf());
          const inProgressData = await rrClient.getByStatus(RequestStatus.InProgress.valueOf());
          if (!pendingData.length && !inProgressData.length) return;

          // ? Browser notifications here
          this.browserNotification();
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      }, 60000);
    },

    browserNotification(): void {
      if (Notification.permission !== "granted") return;

      const notificationOptions = {
        body: "There are pending/in progress requests."
        // icon: ""
      };

      new Notification("Is This Drug Covered?", notificationOptions);

      // PNotify toast
      // info({
      //   title: "Action Required",
      //   text: "There are pending or in-progress requests.",
      //   hide: true,
      //   closer: false,
      //   sticker: false,
      //   destroy: true,
      //   modules: new Map([
      //     [
      //       Confirm,
      //       {
      //         confirm: true,
      //         buttons: [
      //           {
      //             text: "Ok",
      //             primary: true,
      //             click: (notice: any) => notice.close()
      //           }
      //         ]
      //       }
      //     ]
      //   ])
      // });
    },

    toastError(): void {
      error({
        title: "Error fetching data",
        text: "There was an error fetching reimbursement requests.",
        hide: true,
        closer: false,
        sticker: false,
        destroy: true,
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: (notice: any) => notice.close()
                }
              ]
            }
          ]
        ])
      });
    },

    formatDateTime(date: string): string {
      return dayjs(date).format("MMMM DD h:mm A");
    }
  },

  watch: {
    tab() {
      this.rrData = undefined as ReimbursementRequest[] | undefined;

      // Clear existing polling fetch
      clearInterval(this.pollingFetch);

      // Restart polling fetch with the new "status" tab
      this.pollingFetch = setInterval(() => {
        this.getReimbursementRequests(parseInt(this.tab));
      }, 30000);
    }
  }
});
