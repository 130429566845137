export enum UserType {
  Pharmacist = 1,
  Administrator = 2
}

export enum OnlineStatus {
  Online = 1,
  Offline = 2
}

export enum Province {
  ON = 1,
  QC = 2,
  NS = 3,
  NB = 4,
  MB = 5,
  BC = 6,
  PE = 7,
  SK = 8,
  AB = 9,
  NL = 10,
  NT = 11,
  YT = 12,
  NU = 13
}

export enum RequestStatus {
  Pending = 1,
  InProgress = 2,
  Answered = 3,
  ContactingInsuranceCompany = 4
}

export enum EntityStatus {
  Pending = 0,
  Active = 1,
  Deleted = 2
}

export enum SpecialAuthorizationScope {
  Public = 0,
  Insurance = 1
}

export enum SpecialAuthorizationFormStatus {
  NotApplicable = 0,
  Pending = 1,
  Processed = 2
}

export enum EventType {
  Login = 1,
  Logout = 2,
  Online = 3,
  Offline = 4,
  Ping = 5
}

export enum DrugPolicyType {
  Everyone = 1,
  Public = 2
}
